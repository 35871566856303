@import 'shared';

//TODO: Style cookie banner
body {
  #cookieBar {
  }

  .cookieBarInner {
  }

  .cookieBarText {
  }

  .cookieBarButtons {
    display: inline-block;
  }

  .cookieBarButton {
    display: inline-block;
  }
}
