@import '../../styles/shared.scss';

.breakAuto {
  line-break: auto;
}

.superscripted {
  span {
    font-size: px(10);
    margin-right: px(2);
    position: relative;
    top: px(-8);
  }
}
