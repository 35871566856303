@import 'shared';

.CircleButton {
  @include box($circle-button-size);
  @include flex-center;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  color: $dark-grey;
  padding: 0;
  margin: 0;
  border: none;

  &.small {
    @include box($circle-button-size-small);
  }

  &.large {
    @include box($circle-button-size-large);
  }

  &.lightGrey {
    background-color: $light-grey;
  }

  &.white {
    background-color: $white;
  }

  &.shadow {
    background-color: $white;
    box-shadow: 0 #{px(2)} #{px(3)} rgba($black, 0.15);
  }

  &.dark {
    background-color: $dark-grey;
    color: $white;
  }

  &.lightGreyTransparent {
    background-color: $light-grey-transparent;
  }

  &.whiteTransparent {
    background-color: rgba($white, 0.9);
  }

  &.green {
    background-color: $green;
  }

  &.red {
    background-color: $red;
  }

  svg {
    display: block;
  }
}
