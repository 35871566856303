@import 'layout';

// Variables
//
// Add global variables here, color palette, those variables shared by components all applied to global styling

// ====================================================================
// Breakpoints
//
// Window breakpoints
// ====================================================================
$layout-mobile: map-get($layout, mobile) + px;
$layout-tablet: map-get($layout, tablet) + px;
$layout-desktopSm: map-get($layout, desktopSm) + px;
$layout-desktopMd: map-get($layout, desktopMd) + px;
$layout-desktopLg: map-get($layout, desktopLg) + px;

// ====================================================================
// Base palette
//
// Grayscale and brand colors for use across the site.
// ====================================================================
$white: #fff;
$black: #060606;

//Grey
$light-grey: #f1efef;
$light-grey-2: #878787;
$light-grey-3: #757575;
$light-grey-4: #e6e6e6;
$light-grey-5: #b4b4b4;
$light-grey-6: #fbfbfb;
$light-grey-transparent: rgba($light-grey, 0.9);
$dark-grey: #414142;
$dark-grey-2: #202020;
$grey: #aca59e;
$medium-grey: #ddd9d5;

//Secondary
$red: #f6aea9;
$green: #a8dab5;
$yellow: #fde293;
$blue: #aecbfa;

// ====================================================================
// General
//
// Global color variables, used usually in sections
// Global variables used across the web app
// ====================================================================
$page-bg-color: $white;
$element-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);
$mainFont: 'GoogleSans';
$generic-fonts: #{$mainFont}, Helvetica, Arial, sans-serif;
$padding-mid: px(40);

// ====================================================================
// Margins/Gutters
// ====================================================================
$horizontal-margin: px(40);
$specs-half-gutter: calc(55rem / 2);

// ====================================================================
// Nav bar
// ====================================================================
$navbar-height-mobile: 6rem;
$navbar-height-desktop: 8rem;
$header-height: px(60);

// ====================================================================
// List of z-index element
// ====================================================================
$elements: pages, main-nav, swipe-prompt, rotate;

// ====================================================================
// CircleButton
// ====================================================================
$circle-button-size: px(40);
$circle-button-size-large: px(60);
$circle-button-size-small: px(28);

// ====================================================================
// Border Radius
// ====================================================================
$border-radius-lg: 30px;
$border-radius-md: 28px;
$border-radius-sm: 20px;

// ====================================================================
// Feature Card Carousel
// ====================================================================
$feature-carousel-padding: 15px;
$feature-card-width: px(331);
$feature-card-height: 450px;
$feature-card-height-small: 50%;
$feature-card-max-height: px(520);
$feature-card-image-height: 66%;


// ====================================================================
// Circle Button Icons
// ====================================================================
$circle-button-info-icon-width: px(4);
$circle-button-info-icon-height: px(12);
$circle-button-info-offset: px(10);
$circle-button-close-icon-width: px(12);

// ====================================================================
// Tips & FAQs
// ====================================================================
$faq-body-margin-top: px(8);
$faq-body-margin-bottom: px(40);

// ====================================================================
// Transition Tile and Carousel
// ====================================================================
$tile-dimension: px(150);
$tile-border-radius: 18px;
$carousel-border-radius: 55px;
$carousel-image-border-radius: 40px;
$carousel-card-padding: px(20);
