// Globals
//
// Global styling, normalize overwritten
@import 'fonts';
@import 'polyfills';
@import 'shared';
@import 'typography';
@import '~swiper/swiper.scss';
@import '~swiper/components/pagination/pagination.scss';
@import '../components/CookieBanner/CookieBanner.module.scss';

:root {
  /* stylelint-disable length-zero-no-unit */
  --rem-pixel: 0px;
  --vh-val: 0px;
  /* stylelint-enable length-zero-no-unit */
}

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  text-size-adjust: none;
  font-size: var(--rem-pixel);
  font-family: $generic-fonts;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: px(16);
  word-break: keep-all;
}

html,
body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: vh(100);
}

.lang--cjk {
  line-break: anywhere;
}

#__next {
  width: 100vw;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: vh(100);
  height: 100%;
  background-color: $page-bg-color;
  z-index: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

main {
  width: 100vw;
  min-height: vh(100);
  padding-top: $navbar-height-mobile;
  @include z-index(pages);

  @include breakpoint(desktopSm) {
    padding-top: $navbar-height-desktop;
  }
}

section {
  padding: 0 $horizontal-margin;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

button {
  color: $black;
}

button,
input,
a {
  &:focus:not(.focus-visible) {
    outline: none;
  }
}

.swiper-slide {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
