@use "sass:math";

// Sass Mixins
//
// More information about mixins in:
// http://sass-lang.com/documentation/file.SASS_REFERENCE.html#mixins
// Add comment to every function
@import 'vars';

/// Set Regular Font as font family. This mixin's name is generic to abstract the font family name in case it changes
/// @group Fonts
/// @example scss Input
///   .landing-section {
///     @include font-slate-regular();
///   }
@mixin font-slate-regular() {
  font-family: 'SlateStdRegular', #{$generic-fonts};
}

// Use two pixel values to convert line-height into a unitless number
// @example scss Input
//   p {
//     font-size: 16px;
//     line-height: line-height(16, 30);
//   }
@function line-height($font-size, $line-height) {
  @if $line-height==normal {
    @return normal;
  }
  @return math.div($line-height, $font-size);
}

// Use two pixel values to convert letter-spacing into an em value
// @example scss Input
//   p {
//     font-size: 16px;
//     // letter-spacing: -0.1px;
//     letter-spacing: letter-spacing(16, -0.1);
//   }
@function letter-spacing($font-size, $letter-spacing) {
  @if $letter-spacing==normal {
    @return normal;
  }
  @return #{math.div($letter-spacing, $font-size)}em;
}

// Define font-size, line-height and letter-spacing in function
// @example scss Input
//   p {
//     // font-size: 16px;
//     // letter-spacing: -0.1px;
//     // line-height: 1.5; // This is line-height 24px and font-size: 16px
//     @include font-size(16, 24, -0.1)
//   }
@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal, $font-weight: 400) {
  font-size: px($font-size);
  line-height: line-height($font-size, $line-height);
  font-weight: $font-weight;

  @if $letter-spacing !=0 {
    letter-spacing: letter-spacing($font-size, $letter-spacing);
  }
}

@mixin g-sans-bold($font-size, $line-height: normal, $letter-spacing: normal) {
  @include font-size($font-size, $line-height, $letter-spacing, 600);
  font-family: 'Google Sans';
  font-style: normal;
}

@mixin g-sans-regular($font-size, $line-height: normal, $letter-spacing: normal) {
  @include font-size($font-size, $line-height, $letter-spacing, 400);
  font-family: 'Google Sans';
  font-style: normal;
}

/// Improve animation performance for containers
/// @example scss Input
///   .card {
///     @include gpu();
///   }
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

/// Set exactly the same width and height of a component, avoid repeat the same dimension
/// @parameter {Measure unit} $width - Specify width of the box
/// @parameter {Measure unit} $height [$width] - Specify height of the box, the default value is the width
/// @example scss Input
///   .card {
///     @include box(3.2rem);
///   }
///   .card-another {
///     @include box(3.2rem, 2rem);
///   }
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

/// Convert the pixels to rem for in a specific resolution, usually 1440px wide
/// @example scss Input
///   section {
///     height: px(200);
///   }
@function px($px) {
  @return $px * 1rem;
}

/// Set top and left to 0
/// @example scss Input
///   element {
///     @include position-0;
///   }
@mixin position-0 {
  top: 0;
  left: 0;
}

/// Set full size of box and position
/// @parameter {string} $pos - one of relative, absolute, fixed. relative by default
/// @example scss Input
///   element {
///     @include position-100(absolute);
///   }
@mixin position-100($pos: 'relative') {
  position: $pos;
  @include position-0;
  @include box(100%);
}

/// Set display flex and center children horizontally and vertically
/// @example scss Input
///   element {
///     @include flex-center;
///   }
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/// Set display flex and center children vertically
/// @example scss Input
///   element {
///     @include flex-center-vert;
///   }
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

/// Set display flex and center children horizontally
/// @example scss Input
///   element {
///     @include flex-center-horiz;
///   }
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/// Center its element (horizontally and vertically) by using transform
/// @example scss Input
///   element {
///     @include transform-center;
///   }
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/// Set vertical center of its element
/// @example scss Input
///   element {
///     @include transform-center-vert;
///   }
@mixin transform-center-vert {
  top: 50%;
  transform: translateY(-50%);
}

/// Set horizontal center of its element
/// @example scss Input
///   element {
///     @include transform-center-horiz;
///   }
@mixin transform-center-horiz {
  left: 50%;
  transform: translateX(-50%);
}

/// Set z-index value in order of $element variable
/// @example scss Input
///   element {
///     @include z-index(preloader);
///   }
@mixin z-index($id) {
  z-index: index($elements, $id);
}

/// Set breakpoint via media queries
/// @example scss Input
///   element {
///     [...phone styles....]
///     @include breakpoint(tablet) {
///        [...tablet+desktop styles...]
///     }
///   }
@mixin breakpoint($size) {
  @media (min-width: map-get($layout, $size) + px) {
    @content;
  }
}

/// Reset button's look
/// @example scss Input
///   button {
///     @include reset-button;
///   }
@mixin reset-button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: currentColor;
}

/// Add a 'pressed' state for touch devices
/// @example scss Input
///   .element {
///     @include touch-hover(){
//        background-color: $black;
//      }
///   }
@mixin touch-hover() {
  &:hover, // enhanced for no-touch
  &:focus,
  &:active {
    // relay same styles to active for touch devices
    @content;
  }
}

/*
 This mixin is used to create a container of a specific aspect ratio
 $container-width: value should be percent ex. 100%, 90%
 $height: value should be a number i.e. 1.3, 1.4. (height ratio relative to width)
 The mixin will handle converting it into a percentage
*/
@mixin container-aspect-ratio($container-width, $height) {
  // Container for image
  position: relative;
  width: $container-width;
  display: block;
  overflow: hidden;
  &::before {
    content: '';
    float: left;
    padding-top: $height * 100%;
  }
}

@mixin full-size($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@function vh($val: 0) {
  @return calc(#{$val} * var(--vh-val));
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none; /* Non-prefixed version */
  outline: none;
  user-drag: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  &:focus {
    -webkit-touch-callout: none; /* iOS Safari */
    outline: none;
    user-drag: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}

@mixin carouselCardContainer {
  border-radius: $border-radius-lg;
  padding: $feature-carousel-padding;
  overflow: hidden;
  position: relative;
  background-color: $white;
  height: calc(var(--vh, 1vh) * 100 - #{$feature-carousel-padding * 4});
  width: calc(100% - #{$feature-carousel-padding * 4});
  left: $feature-carousel-padding;
  top: $feature-carousel-padding;
}

@mixin carouselCardContainerShorter {
  height: calc(var(--vh, 1vh) * 100 - #{$feature-carousel-padding * 8});
}

@mixin carouselCardCaptionPositioning {
  position: absolute;
  top: $feature-carousel-padding * 2;
  left: $feature-carousel-padding * 2;
}

@mixin tileCaptionPositioning {
  position: absolute;
  top: px(12);
  left: px(12);
}

@mixin pageFullSize {
  @include position-100(absolute);
  overflow-y: scroll;
  //margin-top: ;
  padding-top: $header-height + px(20);
  padding-bottom: vh(10);
}
