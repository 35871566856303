@import 'shared';

.PageTransition {
  width: 100vw;
  position: relative;

  .hide {
    opacity: 0 !important;
    transition: 1s !important;
  }
}
