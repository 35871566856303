@import 'shared';

.ImagePreloader {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  .aspectRatioContainer {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .aspectRatioContainerInner {
    float: left;
  }
  &-fade {
    &--normal {
      transition: opacity 0.4s linear;
    }
    &--slow {
      transition: opacity 0.8s linear;
    }

    &--fast {
      transition: opacity 0.2s linear;
    }
    &--disabled {
      transition: none;
    }
  }
  &--loaded {
    opacity: 1;
  }
  &__image {
    @include full-size;
    object-position: 50% 50%;
    &--cover {
      object-fit: cover;
    }
    &--contain {
      object-fit: contain;
    }
    &--fill {
      object-fit: fill;
    }
    &--scale-down {
      object-fit: scale-down;
    }
  }
}
