@import 'shared';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: $header-height;
  padding: 0 #{px(40)};
  background-color: $white;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;

  .logoContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    appearance: none;
    border: none;
    padding: 0;
    background-color: transparent;
  }

  .logoImage {
    width: px(20);
    height: px(20);
    margin-right: px(10);
  }

  .logoText {
    @include g-sans-regular(14, 1);
    color: #0f0f0f;
  }

  .hamburgerButton {
    svg {
      width: px(14);
    }
  }

  .image {
    @include position-100(absolute);
  }
}

.headerSpacer {
  height: $header-height;
}
