@import 'shared';

.DesktopScreen {
  @include full-size(absolute);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  visibility: hidden;

  .logo {
    position: absolute;
    top: px(40);
    left: px(40);
    width: px(45);
    height: px(45);
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: vh(15);
    margin-bottom: px(40);

    .heading {
      position: relative;
      font-size: px(64);
      line-height: 1.15;
      max-width: 80vw;
      text-align: center;
    }

    .splitLineMask {
      position: relative;
      overflow: hidden;
    }

    .headingLine {
      position: relative;
    }

    .copy {
      @include font-style(body);
    }

    .link {
      font-weight: bold;
    }

    .qrContainer {
      position: relative;
      width: px(140);
      height: px(140);

      .qrCode {
        top: px(5);
        left: px(5);
        width: px(120);
        height: auto;
        object-fit: cover;
        z-index: 2;
      }
    }

    .image {
      @include position-100(absolute);
      object-fit: contain;
    }
  }

  .mask {
    overflow: hidden;
    width: 100%;
    margin-bottom: px(25);
  }

  .instructions {
    margin-bottom: px(40);
  }

  .desktopBkg {
    position: relative;
    width: 70vw;
    max-width: 800px;
  }
}
