/* prettier-ignore */
$typography-system: (
  h1: (28, 30.8, normal, 500),
  h1-small: (24, 24, normal, 500),
  h2: (24, 30, normal, 500),
  h3: (20, 25, normal, 500),
  body: (16, 20, normal, 400),
  body-bold: (16, 20, normal, 500),
  body-small: (15, 20, normal, 400),
  body-small-alt: (14, 20, normal, 400),
  cta: (16, 16, normal, 500),
  eyebrow: (10, 10, normal, 500),
  eyebrowBig: (14, 14, normal, 500),
  caption: (14, 14, normal, 500),
  caption-small: (10, 10, normal, 500),
);

// Setup every font size, line height and letter spacing for all resolutions in a single mixin
// Sample usage:
// @include font-style(h2);
@mixin font-style($key-name) {
  @include font-size(map-get($typography-system, $key-name)...);
}
